import { atom } from 'recoil';

const defaultCaptchaObject = {
  token: '',
  expired: false,
  error: false,
  verified: false,
};

export const loginCaptchaState = atom({
  key: 'loginCaptchaState',
  default: defaultCaptchaObject,
});

export const registerCaptchaState = atom({
  key: 'registerCaptchaState',
  default: defaultCaptchaObject,
});

export const forgotPasswordCaptchaState = atom({
  key: 'forgotPasswordCaptchaState',
  default: defaultCaptchaObject,
});

export const newURLCaptchaState = atom({
  key: 'newURLCaptchaState',
  default: defaultCaptchaObject,
});
