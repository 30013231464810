import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useRef, useEffect } from 'react';
import { environmentState } from '../recoil/environment';

const MyHCaptcha = ({
  captchaStateAtom,
  showRedBorder = false,
  resetCount = 0,
}) => {
  const captchaPubKey = useRecoilValue(environmentState).captchaPub;
  const [captchaState, setCaptchaState] = useRecoilState(captchaStateAtom);

  const updateCaptchaState = (updateData = {}) => {
    setCaptchaState({
      ...captchaState,
      ...updateData,
    });
  };

  const componentRef = useRef(null);
  useEffect(() => {
    componentRef.current.style.border = showRedBorder
      ? '2px solid red'
      : 'none';
  }, [showRedBorder]);

  const captchaRef = useRef(null);
  useEffect(() => {
    captchaRef.current.resetCaptcha();
  }, [resetCount]);

  return (
    <button ref={componentRef}>
      <HCaptcha
        sitekey={captchaPubKey}
        onVerify={(data) => {
          updateCaptchaState({ token: data, verified: true });
        }}
        onError={(e) => {
          updateCaptchaState({ error: e, verified: false });
          resetCount++;
        }}
        onExpire={() => {
          updateCaptchaState({ expired: true, verified: false });
          resetCount++;
        }}
        ref={(e) => {
          captchaRef.current = e;
        }}
      />
    </button>
  );
};

export default MyHCaptcha;
