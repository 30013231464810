import { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Nav from 'react-bootstrap/Nav';
import NavDropDown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userSelector } from '../recoil/user';
import { UserApi } from '../apiWrapper';
import toastSettingsAtom from '../recoil/toast';
import ToastWrapper from '../toastWrapper';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  &:hover {
    color: var(--primary);
  }
  &:active {
    background-color: white;
  }
`;

const Navigation = ({ siteName = 'Slim URL' }) => {
  const [userState, userSetter] = useRecoilState(userSelector);
  const setUser = useCallback(() => userSetter(null), [userSetter]);

  const toastSettingsState = useRecoilValue(toastSettingsAtom);

  useEffect(() => {
    setUser();
  }, [setUser]);

  const [avatarHash, setAvatarHash] = useState('');
  useEffect(() => {
    if (userState) setAvatarHash(stringToHash(userState.email));
  }, [userState]);

  const stringToHash = (str, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  };

  return (
    <Navbar bg="primary" variant="dark" className="justify-content-between" sticky="top">
      <Navbar.Brand href="/">{siteName}</Navbar.Brand>
      <Nav>
        {userState?.email ? (
          <>
            <Image
              src={`https://api.dicebear.com/7.x/identicon/svg?&seed=${avatarHash}&backgroundColor=b6e3f4,c0aede,d1d4f9&backgroundType=gradientLinear,solid&rowColor=00acc1,3949ab,d81b60&size=32`}
              alt="avatar"
            />
            <NavDropDown className="non-decorated black-text" title={userState.email} alignRight>
              <NavDropDown.Item as={StyledLink} to="/my-urls">
                My URLs
              </NavDropDown.Item>
              {/* <NavDropDown.Item as={StyledLink} to="/profile">
                Profile
              </NavDropDown.Item> */}
              <NavDropDown.Divider />
              <NavDropDown.Item
                onClick={() => {
                  UserApi.logout()
                    .then(() => {
                      ToastWrapper.success('Logged out successfully');
                      setUser();
                      setTimeout(() => {
                        window.location.href = '/';
                      }, toastSettingsState.autoClose);
                    })
                    .catch((err) => {
                      ToastWrapper.error(err.message);
                    });
                }}
              >
                Logout
              </NavDropDown.Item>
            </NavDropDown>
          </>
        ) : (
          <Nav.Link href="/login">Login</Nav.Link>
        )}
      </Nav>
    </Navbar>
  );
};

export default Navigation;
