import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { forgotPasswordCaptchaState } from '../recoil/captcha';
import toastSettingsAtom from '../recoil/toast';
import { UserApi } from '../apiWrapper';
import MyHCaptcha from './HCaptcha';
import ToastWrapper from '../toastWrapper';

const ForgotPassword = () => {
  const [formData, setFormData] = useState({ email: '', captchaToken: '' });
  const [validatedForm, setValidatedForm] = useState(false);

  const [captchaState, setCaptchaState] = useRecoilState(
    forgotPasswordCaptchaState
  );
  const toastSettingsState = useRecoilValue(toastSettingsAtom);

  const [captchaBorder, setCaptchaBorder] = useState(false);
  const [resetCount, setResetCount] = useState(0);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      return;
    }
    setValidatedForm(true);
    forgotPasswordFunc(e);
  };

  const forgotPasswordFunc = (e) => {
    e.preventDefault();

    if (!captchaState.verified) {
      ToastWrapper.warning('Please verify the captcha first');
      setCaptchaBorder(true);
      setTimeout(() => {
        setCaptchaBorder(false);
      }, toastSettingsState.autoClose);
      return;
    }

    UserApi.forgotPassword({ ...formData, captchaToken: captchaState.token })
      .then(() =>
        ToastWrapper.success(
          'Password reset email is sent. Make sure to check your spam folder.'
        )
      )
      .catch(() => ToastWrapper.warning('Something went wrong'));

    setCaptchaState({ ...captchaState, verified: false });
    setResetCount(resetCount + 1);
  };

  return (
    <div className="container text-left" style={{ paddingTop: '5%' }}>
      <Form validated={validatedForm} onSubmit={handleSubmit}>
        <Form.Group controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            required
            type="email"
            name="email"
            placeholder="Enter email"
            value={formData.email}
            onChange={(e) => handleChange(e)}
          />
        </Form.Group>
        <div style={{ margin: '1rem 0 1rem 0' }}>
          <MyHCaptcha
            captchaStateAtom={forgotPasswordCaptchaState}
            showRedBorder={captchaBorder}
            resetCount={resetCount}
          />
        </div>
        <Button variant="primary" type="submit" onSubmit={forgotPasswordFunc}>
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default ForgotPassword;
