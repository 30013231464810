import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import MaterialTable from 'material-table';
import { useRef } from 'react';
import { UserApi } from '../apiWrapper';
import ToastWrapper from '../toastWrapper';
import TablePaginationFix from './TablePaginationFix';

const MyURLs = () => {
  const tableRef = useRef(null);

  const tableColumns = [
    {
      title: 'UUID',
      field: 'uuid',
      hidden: true,
    },
    {
      title: 'URL',
      field: 'fullURL',
      render: (rowData) => (
        <a href={rowData.fullURL} target="_blank" rel="noreferrer">
          {rowData.fullURL}
        </a>
      ),
    },
    {
      title: 'Short URL',
      field: 'shortURL',
      render: (rowData) => (
        <a
          href={`https://slimurl.me/${rowData.shortURL}`}
          target="_blank"
          rel="noreferrer"
        >
          {`slimurl.me/${rowData.shortURL}`}
        </a>
      ),
    },
    {
      title: 'Created',
      field: 'createdAt',
      render: (rowData) => new Date(rowData.createdAt).toLocaleString('en-GB'),
    },
  ];

  const refreshTable = async (query) => {
    return new Promise((resolve, reject) => {
      UserApi.listURLs({ page: query.page + 1, limit: query.pageSize })
        .then((res) => {
          if (!res.data) {
            reject("You don't have any URL's");
          }

          //Append details for each URL to initial table data because async call fetching doesn't work
          //because "detailPanel" returns multiple objects (not an array or anything like that, it just doesn't work. All data must be fetched initially)
          for (const url of res.data) {
            UserApi.getURLDetails(url.uuid).then((res) => {
              url.details = res;
              //Sort the array so that the no country ones come last
              url.details.data?.sort((a, b) => {
                if (a.country === b.country) return 0;
                else if (a.country === 'Unknown') return 1;
                else if (b.country === 'Unknown') return -1;
                else return a.country > b.country ? 1 : -1;
              });
            });
          }
          resolve({
            data: res.data,
            page: res.meta.page - 1,
            totalCount: res.meta.count,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const tableActions = [
    {
      icon: 'delete',
      tooltip: 'Delete URL',
      onClick: (event, rowData) => {
        if (window.confirm('Are you sure you want to delete this URL?')) {
          UserApi.deleteURL(rowData.uuid)
            .then(() => {
              tableRef.current.onQueryChange();
              ToastWrapper.success('URL deleted successfully!');
            })
            .catch((err) => {
              ToastWrapper.error(err.message);
            });
        }
      },
    },
  ];

  const showDetailPanel = (rowData) => {
    return (
      <div>
        <h4 className="text-center" style={{ paddingTop: '0.5rem' }}>
          {rowData.details.meta.totalClicks !== 0
            ? 'Total clicks:' + rowData.details.meta.totalClicks
            : 'No clicks yet'}
        </h4>
        {rowData.details.data && (
          <>
            <Table responsive striped hover>
              <thead>
                <tr>
                  <th>Country/Referer</th>
                  <th>Clicks</th>
                </tr>
              </thead>
              <tbody>
                {rowData.details.data?.map((url) => {
                  return (
                    <>
                      <tr className="font-weight-bolder">
                        <td>
                          <b>{url.country}</b>
                        </td>
                        <td style={{ textDecoration: 'underline' }}>
                          {url.countClicks}
                        </td>
                      </tr>
                      {url.referers?.map((referer) => {
                        return (
                          <tr>
                            <td>
                              {referer.site ? referer.site : 'No referer'}
                            </td>
                            <td>{referer.clicks}</td>
                          </tr>
                        );
                      })}
                    </>
                  );
                })}
              </tbody>
            </Table>
          </>
        )}
      </div>
    );
  };

  return (
    <Container>
      <MaterialTable
        title="Your URL's"
        tableRef={tableRef}
        columns={tableColumns}
        data={(query) => refreshTable(query)}
        options={{
          search: false,
          showTitle: false,
          exportButton: true,
        }}
        actions={tableActions}
        detailPanel={(rowData) => showDetailPanel(rowData)}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        components={{
          Pagination: TablePaginationFix,
        }}
      ></MaterialTable>
    </Container>
  );
};

export default MyURLs;
