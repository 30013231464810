import { toast } from 'react-toastify';
import { atom } from 'recoil';

export const toastSettings = {
  position: toast.POSITION.TOP_CENTER,
  pauseOnHover: false,
  pauseOnFocusLoss: true,
  draggable: true,
  autoClose: 4000,
};

export const toastSettingsAtom = atom({
  key: 'toastSettings',
  default: toastSettings,
});

export default toastSettingsAtom;
