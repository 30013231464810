import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { UserApi } from '../apiWrapper';
import ToastWrapper from '../toastWrapper';
import { registerCaptchaState } from '../recoil/captcha';
import EmailPasswordForm from './EmailPasswordForm';

const Register = ({ visibleOnRender = false }) => {
  const [visible, setVisible] = useState(visibleOnRender);
  const [resetCount, setResetCount] = useState(0);

  const registerFunc = (e, formData) => {
    e.preventDefault();

    return UserApi.register(formData).then(() => {
      ToastWrapper.success(
        'Sucessfully registered, check your email for activation link'
      );
      setResetCount(resetCount + 1);
    });
  };

  return (
    <>
      <Button variant="primary" onClick={() => setVisible(!visible)}>
        Register
      </Button>
      <Modal
        show={visible}
        onHide={() => setVisible(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Registration form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EmailPasswordForm
            submitFunc={registerFunc}
            captchaAtom={registerCaptchaState}
            submitButtonText="Register"
            resetCount={resetCount}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="mr-auto ml-1"
            variant="secondary"
            onClick={() => setVisible(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Register;
