export const environmentAtom = {
  development: {
    backendUrl: 'http://localhost:8888',
  },
  production: {
    backendUrl: 'https://api.slimurl.me',
  },
  captchaPub: process.env.REACT_APP_CAPTCHA_PUB,
  cookieToken: 'slimUrlToken',
};

export default environmentAtom;
