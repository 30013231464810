const Footer = () => {
  return (
    <footer className="footer fixed-bottom">
      <div
        className="text-center py-1 footer-content"
        style={{ backgroundColor: 'var(--dark-gray)', color: 'white' }}
      >
        © 2022 SlimURL{' '}
        <a className="footer-contact" href="mailto:support@slimurl.me">
          Get in touch
        </a>
      </div>
    </footer>
  );
};
export default Footer;
