import CreateShortLink from './CreateShortLink';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';

const Homepage = () => {
  return (
    <Container fluid="md">
      <h1 className="text-center" style={{ padding: '1rem' }}>
        Welcome to SlimURL!
      </h1>
      <CreateShortLink />
      <div
        className="row justify-content-center"
        style={{
          paddingLeft: '0.2rem',
          paddingRight: '0.2rem',
          paddingTop: '5rem',
        }}
      >
        <p>
          <b>
            If you want additional features, make sure to create a{' '}
            <Link to="login?registration=true">FREE account</Link>
          </b>
          .
          <br />
          All features are 100% free! Some of the features you will get are:
        </p>
      </div>
      <div
        className="row justify-content-center"
        style={{ paddingLeft: '0.2rem', paddingRight: '0.2rem' }}
      >
        <ul style={{ listStylePosition: 'inside', listStyleType: 'disc' }}>
          <li>Tracking the number of clicks for each link</li>
          <li>Seeing which countries are your links being clicked from</li>
          <li>Seeing what websites are your links being clicked from</li>
          <li>Ability to remove links you no longer want to share</li>
        </ul>
      </div>
    </Container>
  );
};

export default Homepage;
