import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import toastSettingsAtom from '../recoil/toast';
import { RecoilState, useRecoilState, useRecoilValue } from 'recoil'; // eslint-disable-line no-unused-vars
import MyHCaptcha from './HCaptcha';
import ToastWrapper from '../toastWrapper';

/**
 * @callback submitFunc
 * @param {Event} e
 * @param {{email:string,password:string,keepLoggedIn:boolean,captchaToken:string}} formData
 * @returns {Promise}
 */

/**
 * @param {{submitFunc:submitFunc,captchaAtom: RecoilState,submitButtonText:string,showKeepLoggedIn:boolean}}
 */
const EmailPasswordForm = ({
  submitFunc,
  captchaAtom,
  submitButtonText = 'Submit',
  showKeepLoggedIn = false,
}) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    captchaToken: '',
    keepLoggedIn: false,
  });
  const [validatedForm, setValidatedForm] = useState(false);

  const toastSettingsState = useRecoilValue(toastSettingsAtom);

  const [captchaState, setCaptchaState] = useRecoilState(captchaAtom);
  const [captchaBorder, setCaptchaBorder] = useState(false);
  const [resetCount, setResetCount] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      return;
    }
    setValidatedForm(true);

    if (!captchaState.verified) {
      ToastWrapper.warning('Please verify the captcha first');
      setCaptchaBorder(true);
      setTimeout(() => {
        setCaptchaBorder(false);
      }, toastSettingsState.autoClose);
      return;
    }

    submitFunc(e, { ...formData, captchaToken: captchaState.token }).catch(
      (e) => {
        setCaptchaState({ ...captchaState, verified: false });
        setResetCount(resetCount + 1);
        ToastWrapper.error(e.message);
      }
    );
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'keepLoggedIn':
        setFormData({ ...formData, [e.target.name]: e.target.checked });
        break;
      default:
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
        break;
    }
  };

  return (
    <Form validated={validatedForm} onSubmit={handleSubmit}>
      <Form.Group controlId="formEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          required
          type="email"
          name="email"
          placeholder="Enter email"
          value={formData.email}
          onChange={(e) => handleChange(e)}
        />
      </Form.Group>
      <Form.Group controlId="formPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          required
          type="password"
          minLength={6}
          maxLength={30}
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={(e) => handleChange(e)}
        />
      </Form.Group>
      {showKeepLoggedIn && (
        <Form.Check
          type="checkbox"
          name="keepLoggedIn"
          label="Remember me (for 30 days)"
          onChange={(e) => handleChange(e)}
          style={{ borderBottom: '10rem' }}
        />
      )}
      <div style={{ margin: '1rem 0 1rem 0' }}>
        <MyHCaptcha
          captchaStateAtom={captchaAtom}
          showRedBorder={captchaBorder}
          resetCount={resetCount}
        />
      </div>
      <Button variant="primary" type="submit">
        {submitButtonText}
      </Button>
    </Form>
  );
};

export default EmailPasswordForm;
