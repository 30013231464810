import { selector } from 'recoil';
import { environmentAtom } from './atom';

export const environmentState = selector({
  key: 'environmentState',
  get: () => {
    return {
      environment: process.env.NODE_ENV,
      backendUrl: environmentAtom[process.env.NODE_ENV].backendUrl,
      captchaPub: environmentAtom.captchaPub,
      cookieToken: environmentAtom.cookieToken,
    };
  },
});

export default environmentState;
