/* CSS */
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
/* React */
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
/* Components */
import Navigation from './components/Navigation';
import Login from './components/Login';
import NotFound from './components/NotFound';
import ForgotPassword from './components/ForgotPassword';
import Homepage from './components/Homepage';
import Footer from './components/Footer';
import MyURLs from './components/MyURLs';
import ResetPasswordForm from './components/ResetPasswordForm';
/* Utils */
import { toast } from 'react-toastify';
/* State management */
import { RecoilRoot } from 'recoil';
/* Cookie policy */
import CookieConsent from 'react-cookie-consent';
/* Google Analytics */
import ReactGA from 'react-ga';
import AnalyticsRouteChangeTracker from './components/AnalyticsRouteChangeTracker';
/* Force cache clear on new build */
import withClearCache from './ClearCache';

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

const TRACKING_ID = 'UA-213750749-1';
ReactGA.initialize(TRACKING_ID);

// Initial config of toast notifications
toast.configure();

function MainApp() {
  document.body.style.backgroundColor = 'var(--background-color)';

  return (
    <RecoilRoot>
      <Router>
        <AnalyticsRouteChangeTracker />
        <Navigation />
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/404" component={NotFound} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPasswordForm} />
          <Route exact path="/my-urls" component={MyURLs} />
          <Route component={NotFound} />
        </Switch>
      </Router>

      <CookieConsent
        location="bottom"
        buttonText="Understood"
        cookieName="cookieConsentGiven"
        style={{ background: 'var(--dark-gray)', paddingBottom: '2rem' }}
        buttonStyle={{
          backgroundColor: 'var(--blue-info)',
          fontSize: '1rem',
          className: '',
        }}
        expires={150}
      >
        This website uses cookies to enable features such as logging in. We
        don't track you or sell your data. By continuing to use it you accept
        our use of cookies.
      </CookieConsent>

      <Footer />
    </RecoilRoot>
  );
}

export default App;
