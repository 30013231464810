import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import toastSettingsAtom from '../recoil/toast';
import { userAtom } from '../recoil/user';
import { RecoilState, useRecoilState, useRecoilValue } from 'recoil'; // eslint-disable-line no-unused-vars
import { forgotPasswordCaptchaState } from '../recoil/captcha';
import MyHCaptcha from './HCaptcha';
import ToastWrapper from '../toastWrapper';
import { UserApi } from '../apiWrapper';
import deleteAuthCookie from '../helper/cookie';
import Container from 'react-bootstrap/esm/Container';

/**
 * @param {{captchaAtom: RecoilState,submitButtonText:string}}
 */
const ResetPasswordForm = ({ submitButtonText = 'Reset password' }) => {
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
    captchaToken: '',
  });

  // Query param
  const passwordToken = new URLSearchParams(useLocation().search).get('token');

  const setUserState = useRecoilState(userAtom)[1];

  const [validatedForm, setValidatedForm] = useState(false);
  const [errorValue, setErrorValue] = useState('');
  const [matchedPasswords, setMatchedPasswords] = useState(false);

  const toastSettingsState = useRecoilValue(toastSettingsAtom);

  const [captchaState, setCaptchaState] = useRecoilState(
    forgotPasswordCaptchaState
  );
  const [captchaBorder, setCaptchaBorder] = useState(false);
  const [resetCount, setResetCount] = useState(0);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (
      formData.password !== '' &&
      formData.confirmPassword !== '' &&
      formData.password !== formData.confirmPassword
    ) {
      setErrorValue('Passwords do not match');
      setMatchedPasswords(false);
    } else {
      setErrorValue('');
      setMatchedPasswords(true);
    }
  }, [formData.password, formData.confirmPassword]);

  const submitFunc = () => {
    return UserApi.resetPassword({ ...formData, passwordToken });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false || !matchedPasswords) {
      e.stopPropagation();
      return;
    }
    setValidatedForm(true);

    if (!captchaState.verified) {
      ToastWrapper.warning('Please verify the captcha first');
      setCaptchaBorder(true);
      setTimeout(() => {
        setCaptchaBorder(false);
      }, toastSettingsState.autoClose);
      return;
    }

    submitFunc()
      .then(() => {
        ToastWrapper.success('Password reset successfully, you can login now');
        setUserState(null);
        deleteAuthCookie();
        setTimeout(() => {
          window.location.href = '/login';
        }, toastSettingsState.autoClose);
      })
      .catch((e) => {
        setCaptchaState({ ...captchaState, verified: false });
        ToastWrapper.error(e.message);
      });
    setResetCount(resetCount + 1);
  };

  return (
    <Container className="padding-top-50px">
      <Form validated={validatedForm} onSubmit={handleSubmit}>
        <Form.Group controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            required
            type="password"
            minLength={6}
            maxLength={30}
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={(e) => handleChange(e)}
          />
          <Form.Label>Confirm password</Form.Label>
          <Form.Control
            required
            type="password"
            minLength={6}
            maxLength={30}
            name="confirmPassword"
            placeholder="Confirm password"
            value={formData.confirmPassword}
            onChange={(e) => handleChange(e)}
          />
          <p id="formError" style={{ color: 'red' }}>
            {errorValue}
          </p>
        </Form.Group>
        <div style={{ margin: '1rem 0 1rem 0' }}>
          <MyHCaptcha
            captchaStateAtom={forgotPasswordCaptchaState}
            showRedBorder={captchaBorder}
            resetCount={resetCount}
          />
        </div>
        <Button variant="primary" type="submit">
          {submitButtonText}
        </Button>
      </Form>
    </Container>
  );
};

export default ResetPasswordForm;
