import axios from 'axios';
import { deleteAuthCookie } from '../helper';
import { environmentAtom as env } from '../recoil/environment';

//Because of many problems with recoil-outside just duplicate our environment selector here...
//! DO not try to fix ever again
const environment = {
  environment: process.env.NODE_ENV,
  backendUrl: env[process.env.NODE_ENV].backendUrl,
  captchaPub: env.captchaPub,
  cookieTokenName: env.cookieTokenName,
};

const API = axios.create({
  baseURL: environment.backendUrl,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

API.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        if (error.response.data.error === 'Not valid token') deleteAuthCookie();

        setTimeout(() => {
          window.location.href = '/login';
        }, 1000);
      }

      throw new Error(
        error.response.data.error ??
          `Unknown error with status code ${error.response.status}`
      );
    } else {
      throw new Error(error.message);
    }
  }
);

//This instance won't redirect to /login on 401
const APINoRedirect = axios.create({
  baseURL: environment.backendUrl,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

APINoRedirect.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response) {
      throw new Error(
        error.response.data.error ??
          `Unknown error with status code ${error.response.status}`
      );
    } else {
      throw new Error(error.message);
    }
  }
);

export class UserApi {
  /**
   * @param {{email:string, password:string, captchaToken:string}} loginCredentials
   */
  static register = ({ email, password, captchaToken }) =>
    API.post('/user/register', { email, password, captchaToken });

  /**
   * @param {{email:string, password:string,captchaToken:string, keepLoggedIn:boolean}} loginCredentials
   */
  static login = ({ email, password, captchaToken, keepLoggedIn }) =>
    APINoRedirect.post('/user/login', {
      email,
      password,
      captchaToken,
      keepLoggedIn,
    });

  /**
   * @param {string} token
   */
  static activate = (token) => API.post(`/user/activate/${token}`);

  /**
   * @typedef {Object} PaginationParams
   * @property {number} limit The number of items to return per page.
   * @property {number} page The page number to return.
   */

  /**
   * @param {PaginationParams} params Query params for pagination
   */
  static listURLs = (params) => API.get('/user/urls', { params: params });

  /**
   * @param {string} urlId
   */
  static getURLDetails = (urlId) => API.get(`/user/urls/${urlId}`);

  /**
   * @param {string} urlId
   */
  static deleteURL = (urlId) => API.delete(`/user/urls/${urlId}`);

  static deleteUser = () => API.delete('/user/delete-user');

  static forgotPassword = ({ email, captchaToken }) =>
    API.get('/user/send-reset-password-email', {
      params: { email, captchaToken },
    });

  static resetPassword = ({
    password,
    confirmPassword,
    captchaToken,
    passwordToken,
  }) =>
    APINoRedirect.post(`/user/reset-password/${passwordToken}`, {
      password,
      confirmPassword,
      captchaToken,
    });

  static logout = () => API.post('/user/logout');
}

export class UrlApi {
  static redirectUrl = (urlCode) => API.get(`/redirect/${urlCode}`);

  static urlCodeExists = (urlCode) => API.get(`/exists/${urlCode}`);

  /**
   * @param {{url:string}} url
   */
  static createURL = ({ url, captchaToken }) =>
    API.post('/new-url', { url, captchaToken });
}
