import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="container text-center">
      <img
        src="https://i.imgur.com/Q2BAOd2.png"
        alt="Not Found"
        style={{
          padding: '1rem',
          width: '35%',
          height: '35%',
        }}
      />
      <h1>The page you were looking for was not found</h1>
      <p style={{ fontSize: 'large' }}>
        Remember that the short URL codes are case sensitive
      </p>
      <p style={{ fontSize: 'large' }}>
        Perhaps you would like to return to the <Link to="/">homepage</Link>
      </p>
    </div>
  );
};

export default NotFound;
