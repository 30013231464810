import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { selector } from 'recoil';
import environmentState from '../environment/withEnvState';
import { userAtom } from '.';

export const userSelector = selector({
  key: 'userState',
  get: ({ get }) => {
    return get(userAtom);
  },

  set: ({ set, get }) => {
    const environment = get(environmentState);
    const token = Cookies.get(environment.cookieToken);
    if (!token) {
      set(userAtom, null);
    } else {
      const user = jwtDecode(token);
      if (user.exp * 1000 < Date.now()) {
        set(userAtom, null);
      } else {
        set(userAtom, user);
      }
    }
  },
});

export default userSelector;
