import { toast } from 'react-toastify';
import { toastSettings } from '../recoil/toast';

export default class ToastWrapper {
  static success(message) {
    toast.success(message, toastSettings);
  }

  static warning(message) {
    toast.warning(message, toastSettings);
  }

  static error(message) {
    toast.error(message, toastSettings);
  }
}
