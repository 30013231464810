import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useState, useRef } from 'react';
import MyHCaptcha from './HCaptcha';
import { newURLCaptchaState } from '../recoil/captcha';
import { UrlApi } from '../apiWrapper';
import { useRecoilState, useRecoilValue } from 'recoil';
import toastSettingsAtom from '../recoil/toast';
import ToastWrapper from '../toastWrapper';

const CreateShortLink = ({ showCaptcha = false }) => {
  const toastSettingsState = useRecoilValue(toastSettingsAtom);

  const [validatedForm, setValidatedForm] = useState(false);

  const [captchaState, setCaptchaState] = useRecoilState(newURLCaptchaState);
  const [captchaBorder, setCaptchaBorder] = useState(false);

  const [url, setUrl] = useState('');
  const urlRef = useRef(null);

  const [shortUrl, setShortUrl] = useState('');
  const updateURLCode = (shortUrl) => {
    setShortUrl(`https://slimurl.me/${shortUrl}`);
  };

  const [resetCount, setResetCount] = useState(0);

  const [isCopied, setIsCopied] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      return;
    }

    //In case user didn't copy protocol set it to http
    //Had to use refs because "url" variable wasn't being updated.. I know I am bad :(
    if (
      !['http://', 'https://'].some((protocol) =>
        urlRef.current.value.startsWith(protocol)
      )
    ) {
      urlRef.current.value = `http://${urlRef.current.value}`;
    }
    // Validate the form and call the backend
    setValidatedForm(true);
    createURLFunc();
  };

  const createURLFunc = () => {
    if (showCaptcha && !captchaState.verified) {
      ToastWrapper.warning('Please verify the captcha first');
      setCaptchaBorder(true);
      setTimeout(() => {
        setCaptchaBorder(false);
      }, toastSettingsState.autoClose);
      return;
    }

    UrlApi.createURL({
      url: urlRef.current.value,
      captchaToken: captchaState.token,
    })
      .then((resp) => {
        if (resp.shortURL) {
          setUrl('');
          setIsCopied(false);
          setValidatedForm(false);
          updateURLCode(resp.shortURL);
          setResetCount(resetCount + 1);
        }
      })
      .catch((e) => {
        setCaptchaState({ ...captchaState, verified: false });
        setResetCount(resetCount + 1);
        ToastWrapper.error(e.message);
      });
  };

  return (
    <div>
      <Form validated={validatedForm} onSubmit={handleSubmit}>
        <Form.Group controlId="formURL">
          <InputGroup>
            <Form.Control
              required
              ref={(ref) => (urlRef.current = ref)}
              maxLength={2000}
              className="input-group-append"
              //Changed to text insted of url so if user forgots to enter protocol we can forcefully set it to http
              type="text"
              placeholder="Paste URL here"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
            <Button variant="primary" type="submit">
              Shorten
            </Button>
          </InputGroup>
        </Form.Group>
        {showCaptcha && (
          <div style={{ margin: '1rem 0 1rem 0' }}>
            <MyHCaptcha
              captchaStateAtom={newURLCaptchaState}
              showRedBorder={captchaBorder}
              resetCount={resetCount}
            />
          </div>
        )}
        {!!shortUrl && (
          <Card className="text-center">
            <Card.Body>
              <Card.Title>Slimmed URL:</Card.Title>
              <Card.Link href={shortUrl}>{shortUrl + ' '}</Card.Link>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Copy link to clipboard</Tooltip>}
              >
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                    navigator.clipboard.writeText(shortUrl);
                    setIsCopied(true);
                  }}
                >
                  {isCopied ? 'Copied' : 'Copy'}
                </Button>
              </OverlayTrigger>
            </Card.Body>
          </Card>
        )}
      </Form>
    </div>
  );
};

export default CreateShortLink;
