import Register from './Register';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { loginCaptchaState } from '../recoil/captcha';
import toastSettingsAtom from '../recoil/toast';
import { useRecoilValue } from 'recoil';
import { UserApi } from '../apiWrapper';
import EmailPasswordForm from './EmailPasswordForm';
import { Link } from 'react-router-dom';
import ToastWrapper from '../toastWrapper';

const Login = () => {
  const toastSettingsState = useRecoilValue(toastSettingsAtom);

  // Query params
  const search = useLocation().search;
  const showRegistration =
    new URLSearchParams(search).get('registration') === 'true';

  const [resetCount, setResetCount] = useState(0);

  const loginFunc = (e, formData) => {
    e.preventDefault();

    return UserApi.login(formData).then(() => {
      ToastWrapper.success('Sucessfully logged in');
      setResetCount(resetCount + 1);
      setTimeout(() => {
        window.location.href = '/';
      }, toastSettingsState.autoClose);
    });
  };

  return (
    <>
      <div className="container text-left padding-top-50px" id="login-page">
        <EmailPasswordForm
          submitFunc={loginFunc}
          captchaAtom={loginCaptchaState}
          submitButtonText="Login"
          showKeepLoggedIn={true}
        />
        <ToastContainer limit={3} />
      </div>
      <p className="container text-center">
        Don't have an account yet?{' '}
        <Register
          visibleOnRender={showRegistration}
          style={{ paddingLeft: '1rem', border: '1px solid red' }}
        />
        <br />
        <Link to="/forgot-password">Forgot your password?</Link>
      </p>
    </>
  );
};

export default Login;
