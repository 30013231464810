import Cookies from 'js-cookie';
import environmentAtom from '../recoil/environment/atom';

/**
 * After calling must set user state to null to force refresh of navbar
 */
export const deleteAuthCookie = () => {
  Cookies.remove(environmentAtom.cookieToken);
};

export default deleteAuthCookie;
